import { Reducer } from 'redux';
import { Action } from 'redux-actions';

import { produceReducer } from '../wrapper';

import { GET_USER_INFO_SUCCESS, SET_USER_INFO, UserInfo, USER_LOGOUT } from './actionTypes';

export interface UserState {
    userInfo: UserInfo;
}

const initialState: UserState = {
    userInfo: {
        id: '',
        email: '',
        firstName: '',
        lastName: '',
        postCodeId: '',
        sex: '',
        address: '',
        avatar: null,
        excludeShowWarningProductIds: '',
        excludeProductIds: '',
    },
};

const actionHandlers = {
    [GET_USER_INFO_SUCCESS]: (draftStore: UserState, action: Action<UserInfo>) => {
        draftStore.userInfo = action.payload;
    },
    [USER_LOGOUT]: (draftStore: UserState) => {
        draftStore.userInfo = initialState.userInfo;
        window.location.reload();
    },
    [SET_USER_INFO]: (draftStore: UserState, action: Action<UserInfo>) => {
        draftStore.userInfo = action.payload;
    },
};

export default produceReducer(actionHandlers, initialState) as Reducer<UserState>;
