export const route = {
    HOME: '/',
    LOGIN: '/login',
    FORGOT_PASSWORD: '/forgot-password',
    SIGN_UP: '/sign-up',
    VERIFICATION: '/verification-code',
    RESET_PASSWORD: '/reset-password',
    PASSWORD_RESET_CODE: '/password-reset-code',
    RESET_PASSWORD_SUCCESS: '/reset-password-success',
    CHANGE_PASSWORD: '/change-password',
    PRODUCT_DETAIL: '/product-detail/:id',
    LIST_PRODUCT: '/list-product',
    SEARCH_RESULT: '/search',
    PRODUCT_CATEGORY: '/product-category/:id',
    TOP_VIEWED_PRODUCTS: '/top-viewed-products',
    PROFILE: {
        PERSONAL_INFO: '/profile',
        CHANGE_PASSWORD: '/profile/change-password',
        CONSUMPTION_TRACKER: '/profile/consumption-tracker',
        WISHLIST: '/profile/wishlist',
        ALLERGEN: '/profile/allergen',
    },
    RECOMMENDED: '/recommended',
};

export enum RouteConfig {
    ADMIN = '/admin',
    USER = '/',
}

export const adminRoute = {
    LOGIN: '/admin/login',
    HOME: '/admin',
    COUNTRY: '/admin/country',
    CATEGORY: '/admin/category',
    PRODUCT: '/admin/products',
    RETAILERS: '/admin/retailer',
    RETAILERS_BRANCHES: '/admin/retailer-branches/:name',
    RETAILERS_PRODUCTS: '/admin/retailer-products/:id',
    PRODUCT_VIEWS: '/admin/product-views',
    PRODUCT_VIEWS_DETAIL: '/admin/product-views/:upc',
    INGREDIENTS: '/admin/ingredient',
    ALLERGENS: '/admin/allergens',
    APPLICATIONS: '/admin/applications',
};
