export const GET_USER_INFO = 'GET_USER_INFO';
export const GET_USER_INFO_SUCCESS = 'GET_USER_INFO_SUCCESS';
export const USER_LOGOUT = 'USER_LOGOUT';
export const SET_USER_INFO = 'SET_USER_INFO';

export interface UserInfo {
    id: string;
    email: string;
    firstName: string;
    avatar: string | null;
    lastName: string;
    postCodeId: string;
    sex: string;
    address: string | null;
    excludeShowWarningProductIds: string;
    excludeProductIds: string;
}
